<template>
  <b-sidebar id="add-new-categoria-patrocinadores-sidebar" :visible="isEditSidebarActive" bg-variant="white" sidebar-class="sidebar-lg" shadow backdrop no-header right @hidden="resetForm" @change="(val) => $emit('update:is-add-new-sidebar-active', val)">
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">Editar participante</h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
      </div>
      <validation-observer
        ref="refFormObserver"
      >
        <b-form
          class="p-2"
          @submit.prevent="submit()"
        >
        <p>Categoria: {{category}}</p>
        <validation-provider
          v-slot="{ errors }"
          name="name"
          rules="required"
        >
          <b-form-group
            label="Nome"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="form.name"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
        <validation-provider
          v-slot="{ errors }"
          name="cpf"
          rules="required"
          :value="data.name"
        >
          <b-form-group
            label="CPF"
            label-for="cpf"
          >
            <b-form-input
              id="cpf"
              v-model="form.cpf"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>        
        <validation-provider
          v-slot="{ errors }"
          name="email"
          rules="required"
        >
          <b-form-group
            label="Email"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="form.email"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
        <validation-provider
          v-slot="{ errors }"
          name="secretaria"
          rules=""
        >
          <b-form-group
            v-if="appType == 'JOURNALISTIC'"
            label="Secretaria"
            label-for="secretaria"
            :state="errors.length > 0 ? false : null"
          >
            <v-select
              id="secretaria"
              v-model="form.secretaria"
              :options="[
                {text:'Administrador',value:'ADMIN'},
                {text:'Mestre',value:'MASTER'},
                {text:'Pagador(a)', value: 'PAID'},
                {text:'Comum', value: 'COMMON'},
                {text:'Secretário(a)', value: 'SECRETARY'},
                {text:'Secretário(a) Geral', value: 'SECRETARY-GENERAL'},
                {text:'Tesoureiro', value: 'TREASURER'},
                {text:'Presidente', value: 'PRESIDENT'},
                {text:'Vice-Presidente', value: 'VICE-PRESIDENT'},
                {text:'Palestrante', value: 'SPEAKER'},
                {text:'Patrocinador', value: 'SPONSOR'},
                {text:'Diretor Científico', value: 'SCIENTIFIC-DIRECTOR'},
                {text:'Diretor do Curso', value: 'COURSE-DIRECTOR'},
                {text:'Diretor de Comunicação', value: 'COMMUNICATION-DIRECTOR'},
                {text:'Administrador do Congresso', value: 'CONGRESS-ADMINISTRATOR'},
                {text:'Professor', value: 'PROFESSOR'},
                {text:'Orientador', value: 'ADVISOR'},
                {text:'Patrocinador de Comunicação', value: 'SPONSOR-COMMUNICATION'},
                {text:'Patrocinador do Congresso', value: 'CONGRESS-SPONSOR'},
                {text:'Jurídico', value: 'JURIDICAL'},
                {text:'Presidente de Estado', value: 'STATE-PRESIDENT'},
                {text:'Associado SNNO', value: 'SNNO-ASSOCIATE'},
                {text:'Gerente de Arte', value: 'ART-MANAGER'},
              ]"
              :reduce="option => option.value"
              label="text"
              :state="errors.length > 0 ? false : null"
            />

            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
        <validation-provider
          v-if="appType == 'JOURNALISTIC'"
          v-slot="{ errors }"
          name="cargo"
          rules=""
        >
          <b-form-group
            label="Cargo"
            label-for="cargo"
            :state="errors.length > 0 ? false : null"
          >
            <v-select
              id="cargo"
              v-model="form.cargo"
              :options="[
                {text:'Administrador',value:'ADMIN'},
                {text:'Mestre',value:'MASTER'},
                {text:'Pagador(a)', value: 'PAID'},
                {text:'Comum', value: 'COMMON'},
                {text:'Secretário(a)', value: 'SECRETARY'},
                {text:'Secretário(a) Geral', value: 'SECRETARY-GENERAL'},
                {text:'Tesoureiro', value: 'TREASURER'},
                {text:'Presidente', value: 'PRESIDENT'},
                {text:'Vice-Presidente', value: 'VICE-PRESIDENT'},
                {text:'Palestrante', value: 'SPEAKER'},
                {text:'Patrocinador', value: 'SPONSOR'},
                {text:'Diretor Científico', value: 'SCIENTIFIC-DIRECTOR'},
                {text:'Diretor do Curso', value: 'COURSE-DIRECTOR'},
                {text:'Diretor de Comunicação', value: 'COMMUNICATION-DIRECTOR'},
                {text:'Administrador do Congresso', value: 'CONGRESS-ADMINISTRATOR'},
                {text:'Professor', value: 'PROFESSOR'},
                {text:'Orientador', value: 'ADVISOR'},
                {text:'Patrocinador de Comunicação', value: 'SPONSOR-COMMUNICATION'},
                {text:'Patrocinador do Congresso', value: 'CONGRESS-SPONSOR'},
                {text:'Jurídico', value: 'JURIDICAL'},
                {text:'Presidente de Estado', value: 'STATE-PRESIDENT'},
                {text:'Associado SNNO', value: 'SNNO-ASSOCIATE'},
                {text:'Gerente de Arte', value: 'ART-MANAGER'},
              ]"
              :reduce="option => option.value"
              label="text"
              :state="errors.length > 0 ? false : null"
            />

            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
        <validation-provider
          v-if="appType == 'MEDICAL'"
          v-slot="{ errors }"
          name="crm"
          rules=""
        >
          <b-form-group
            label="CRM"
            label-for="crm"
          >
            <b-form-input
              id="crm"
              v-model="form.crm"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
        <validation-provider
          v-if="appType == 'MEDICAL'"
          v-slot="{ errors }"
          name="crm_uf"
          rules=""
        >
          <b-form-group
            label="CRM UF"
            label-for="crm_uf"
            :state="errors.length > 0 ? false : null"
          >
            <v-select
              id="crm_uf"
              v-model="form.crm_uf"
              :options="states"
              :reduce="option => option.value"
              label="text"
              :state="errors.length > 0 ? false : null"
            />

            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
        
        
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
          >
            Atualizar
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            @click="hide"
          >
            Cancelar
          </b-button>
        </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { BSidebar, BForm, BFormGroup, BFormInput, BButton, VBTooltip,} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, extend  } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import {
  required, min_value as minValue, email, integer, required_if, min, confirmed
} from 'vee-validate/dist/rules'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import axios from 'axios'

extend('required', {
  ...required,
  message: 'Esse campo é obrigatório.',
})
export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    isEditSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    participante: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      form: {
        id: null,
        name: null,
        cpf: null,
        email: null,
        secretaria: null,
        cargo: null,
        crm: null,
        crm_uf: null
      },
      states: [
        { value: '', text: 'Digite o estado' },
        { value: 'AC', text: 'AC - Acre' },
        { value: 'AL', text: 'AL - Alagoas' },
        { value: 'AP', text: 'AP - Amapá' },
        { value: 'AM', text: 'AM - Amazonas' },
        { value: 'BA', text: 'BA - Bahia' },
        { value: 'CE', text: 'CE - Ceará' },
        { value: 'DF', text: 'DF - Distrito Federal' },
        { value: 'ES', text: 'ES - Espírito Santo' },
        { value: 'GO', text: 'GO - Goiás' },
        { value: 'MA', text: 'MA - Maranhão' },
        { value: 'MT', text: 'MT - Mato Grosso' },
        { value: 'MS', text: 'MS - Mato Grosso do Sul' },
        { value: 'MG', text: 'MG - Minas Gerais' },
        { value: 'PA', text: 'PA - Pará' },
        { value: 'PB', text: 'PB - Paraíba' },
        { value: 'PR', text: 'PR - Paraná' },
        { value: 'PE', text: 'PE - Pernambuco' },
        { value: 'PI', text: 'PI - Piauí' },
        { value: 'RJ', text: 'RJ - Rio de Janeiro' },
        { value: 'RN', text: 'RN - Rio Grande do Norte' },
        { value: 'RS', text: 'RS - Rio Grande do Sul' },
        { value: 'RO', text: 'RO - Rondônia' },
        { value: 'RR', text: 'RR - Roraima' },
        { value: 'SC', text: 'SC - Santa Catarina' },
        { value: 'SP', text: 'SP - São Paulo' },
        { value: 'SE', text: 'SE - Sergipe' },
        { value: 'TO', text: 'TO - Tocantins' },
      ],
      previewUrl: null,
      appType: process.env.VUE_APP_TYPE,
    }
  },
  methods: {
    previewImage(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();

        reader.onload = (e) => {
          this.previewUrl = e.target.result;
        };

        reader.readAsDataURL(file);
      } else {
        this.previewUrl = null;
      }
    },
    resetForm() {
      this.form = {
        id: null,
        name: null,
        cpf: null,
        email: null,
        secretaria: null,
        cargo: null,
        crm: null,
        crm_uf: null,
      }
      this.logo_old = null,
      this.$emit('close')
    },
    submit() {
      this.$refs.refFormObserver.validate().then(async (success) => {
        if (success) {
          try {
            const formData = new FormData()
            formData.append('id', this.form.id)
            formData.append('name', this.form.name)
            formData.append('cpf', this.form.cpf)
            formData.append('email', this.form.email)
            formData.append('secretaria', this.form.secretaria)
            formData.append('cargo', this.form.cargo)
            formData.append('crm', this.form.crm)
            formData.append('crm_uf', this.form.crm_uf)

            axios.post(`${process.env.VUE_APP_API}/events/update-participant/${this.form.id}`, formData)
              .then(response => {
                console.log(response.data)
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Sucesso ao atualizar!',
                    icon: 'InfoIcon',
                    variant: 'success',
                  }
                })
                this.$emit('close')
                this.$emit('onSaved', true)
                this.resetForm()
                this.isEditSidebarActive = false
              })
              .catch(error => {
                console.log(error)
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    icon: 'InfoIcon',
                    title: error.message,
                    variant: 'danger',
                  }
                })
              })

            
          } catch (err) {
            this.$toast({
              component: ToastificationContent,
              props: {
                icon: 'InfoIcon',
                title: err.message,
                variant: 'danger',
              }
            })
          }
        }
      })
    },
  },
  mounted() {
    this.form.id = this.data.id
    this.form.name = this.data.name
    this.form.cpf = this.data.cpf
    this.form.email = this.data.email
    this.form.secretaria = this.data.secretaria
    this.form.cargo = this.data.cargo
    this.form.crm = this.data.crm
    this.form.crm_uf = this.data.crm_uf
    console.log(this.category)
  }
}

</script>

<style>

</style>