<template>
  <section>
    
    <b-card no-body class="mb-0 w-100">
      <div class="ml-2 mt-2">
        <router-link
          :to="`/administrador/eventos_conf/${this.$route.params.id}`"
          class="link-none"
        >
          <feather-icon icon="ArrowLeftIcon" size="20" class="" />
        </router-link>
      </div>
      <div class="m-2">
        <add-new 
          v-if="isAddNewSidebarActive" 
          :is-add-new-sidebar-active.sync="isAddNewSidebarActive"
          :participante="participante" 
          @refresh="refresh()" 
          @close="isAddNewSidebarActive = false"
        />
        <add-new-user
          v-if="isAddNewUserSidebarActive" 
          :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
          @close="isAddNewUserSidebarActive = false"
        />
        <edit
          v-if="isEditSidebarActive"
          :is-edit-sidebar-active.sync="isEditSidebarActive"
          :data="rowData"
          :participante="participante"
          @refresh="refresh()"
          @close="isEditSidebarActive = false"
        />
        <cracha
          v-if="isEmitirCrachaSidebarActive"
          :is-edit-sidebar-active.sync="isEmitirCrachaSidebarActive"
          :data="rowData"
          @refresh="refresh()"
          @close="isEmitirCrachaSidebarActive = false"
        />
        <b-row>
          <b-col cols="12" md="3" class="mb-2 mb-sm-0">
            <div class="d-flex align-items-center justify-content-end justify-content-sm-start">
              <label>Mostrar</label>
              <v-select style="width: 100px !important;" v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions" :clearable="false" class="d-flex justify-content-center per-page-selector d-inline-block mx-50"  />
              <label class="">Participantes</label>
            </div>
          </b-col>
          <b-col cols="12" sm="12" md="5" class="mb-2 mb-sm-0">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchValue" class="d-inline-block" placeholder="Pesquisar..." />
            </div>
          </b-col>
          <b-col cols="12" sm="12" md="4">
              <div class="d-flex align-items-center justify-content-end">
                <b-button variant="primary" @click="isAddNewSidebarActive = true"  class="mr-1">
                  <span class="text-nowrap">Incluir participante</span>
                </b-button>
                <b-button
                  variant="primary"
                  @click="isAddNewUserSidebarActive = true"
                >
                  <span class="text-nowrap">Cadastrar</span>
                </b-button>
              </div>
          </b-col>
        </b-row>
      </div>
      <div class="overflow-auto">
        <b-table id="tableParticipantes" ref="tableParticipantes" hover striped :items="participantes" :fields="tableColumns" :filter="searchValue" primary-key="id" :total-rows="rows" :per-page="perPage" :sort-by="sortBy" :sort-desc="sortDesc" :current-page="currentPage" show-empty empty-text="Nenhuma informação encontrada" busy.sync="isBusy" empty-filtered-text="Nenhum registro filtrado">
          <template slot="empty">
            <div v-if="isBusy" class="text-center" >
              <b-spinner class="align-middle" />
              
            </div>
            <div v-else class="text-center">Nenhuma informação encontrada</div>
          </template>
          <template #cell(actions)="data">
            <div class="d-block d-sm-flex">
              <a class="text-nowrap" @click="edit(data.item)">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Editar </span>
              </a>
              <span class="d-none d-sm-flex mx-1">||</span>
              <span class="d-flex d-sm-none text-center">-----------</span>
              <a class="text-nowrap" @click="del(data.item.id)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Deletar</span>
              </a>
              <span class="d-none d-sm-flex mx-1">||</span>
              <span class="d-flex d-sm-none text-center">-----------</span>
              <a class="text-nowrap" @click="emitirCracha(data.item)">
                <feather-icon icon="TagIcon" />
                <span class="align-middle ml-50">Cracha</span>
              </a>
            </div>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            </b-col>
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
              <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="tableParticipantes" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item" >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18"/>
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-card>
  </section>
</template>

<script>
  import { BCard, BRow, BCol, BTable, BFormInput, } from 'bootstrap-vue'
  import vSelect from 'vue-select'
  import { ref } from '@vue/composition-api'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

  import AddNew from './AddNew.vue'
  import AddNewUser from './AddNewUser.vue'
  import Edit from './Edit.vue'
  import Cracha from './Cracha.vue'
  import axios from 'axios'

  export default {
    components: {
      BCard,
      BRow,
      BCol,
      BTable,
      vSelect,
      BFormInput,
      AddNew,
      AddNewUser,
      Edit,
      Cracha
    },
    setup() {
      const isAddNewSidebarActive = ref(false)
      const isAddNewUserSidebarActive = ref(false)
      const isEditSidebarActive = ref(false)
      const isEmitirCrachaSidebarActive = ref(false)
      return {
        isAddNewSidebarActive,
        isAddNewUserSidebarActive,
        isEditSidebarActive,
        isEmitirCrachaSidebarActive
      }
    },
    data() {
      return {
        perPage: 10,
        perPageOptions: [10, 25, 50, 100, 200, 300, 400, 500],
        searchValue: '',
        rows: 0,
        tableColumns: [
          {
            key: 'name',
            label: 'NOME',
            sortable: true,
          },
          {
            key: 'cpf',
            label: 'CPF',
            sortable: true,
          },
          {
            key: 'created_at',
            label: 'DATA',
            sortable: true,
          },
          {
            key: 'actions',
            label: 'Ações',
          },
        ],
        rowData: {},
        sortBy: 'id',
        sortDesc: true,
        currentPage: 1,
        isBusy: false,
        participante: '',
        participantes: [
        ]
      }
    },
    watch: {
      isAddNewSidebarActive(value) {
        if (!value) {
          this.getListParticipantes();
        }
      },
      isAddNewUserSidebarActive(value) {
        if (!value) {
          this.getListParticipantes();
        }
      },
      isEditSidebarActive(value) {
        if (!value) {
          this.getListParticipantes();
        }
      }
    },
    methods: {
      getLogoUrl(logo) {
        return `${process.env.VUE_APP_API_BASE}/storage/${logo}`
      },
      refresh(saved) {
        this.getListParticipantes();
      },
      getListParticipantes(){

        axios.get(`${process.env.VUE_APP_API}/events/get-participants/${this.$route.params.id}`)
          .then(response => {
            this.participantes = response.data.response.participantes.map(participante => {
              const createdAt = participante.created_at;
              const formattedDate = new Date(createdAt).toLocaleDateString('pt-BR');
              return {
                ...participante,
                created_at: formattedDate
              };
            });
            console.log('heyhey', this.participantes);
          })
          .catch(error => {
            console.log(error);
          });

      },
      emitirCracha(data){
        this.rowData.id = data.id
        this.rowData.name = data.name
        this.rowData.secretaria = data.secretaria
        this.rowData.cargo = data.cargo
        this.isEmitirCrachaSidebarActive = true
      },
      edit(data) {
        this.rowData.id = data.id
        this.rowData.name = data.name
        this.rowData.cpf = data.cpf
        this.rowData.email = data.email
        this.isEditSidebarActive = true
      },
      del(id) {
        this.$swal({
          title: 'Deletar participante do evento?',
          text: 'Você não será capaz de reverte essa ação!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3b65f0',
          cancelButtonColor: '#c1c1c1',
          cancelButtonText: 'Não',
          confirmButtonText: 'Sim, desejo deletar!',
          showLoaderOnConfirm: true,
          preConfirm: () => axios.post(`${process.env.VUE_APP_API}/events/delete-participant/${id}`)
              .then(response => {
                console.log(response.data)
                console.log(this.rowData.id)
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Sucesso ao deletar!',
                    icon: 'InfoIcon',
                    variant: 'success',
                  }
                })
                this.$emit('close')
                this.getListParticipantes()
              })
              .catch(error => {
                console.log(error)
                console.log(this.rowData.id)
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    icon: 'InfoIcon',
                    title: error.message,
                    variant: 'danger',
                  }
                })
              }),
          allowOutsideClick: () => !this.$swal.isLoading()
        }).then((result) => {
          if (result.isConfirmed) {
            this.$swal.fire(
              'Deletado!',
              'Participante deletado com sucesso.',
              'success'
            )
          }
        })
      },
    },
    beforeMount() {
      this.getListParticipantes()
    },
  }
</script>

<style lang="scss">
.link-none {
  text-decoration: none !important;
  color: inherit;
}

.vs__dropdown-toggle {
  width: 82px;
  height: 38px;
}
</style>